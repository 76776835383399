import React from 'react';
import styled from "styled-components";
import PropTypes from 'prop-types';

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
  
  > tspan {
    white-space: pre;
  }
`;

const AverageHeartRate = ({color}) => (
  <StyledSvg viewBox="0 0 22 20" width="22" height="20">
    <path fillRule="evenodd"
      fill={color || "rgb(153, 153, 153)"} id="icon"
      d="M0.77 8.51C0.58 7.91 0.48 7.27 0.48 6.61C0.48 4.3 1.74 2.28 3.6 1.23C4.46 0.75 5.46 0.47 6.51 0.47C8.38 0.47 10.06 1.34 11.16 2.69C12.27 1.34 13.94 0.47 15.81 0.47C16.87 0.47 17.86 0.75 18.73 1.23C20.59 2.28 21.85 4.3 21.85 6.61C21.85 7.27 21.74 7.91 21.55 8.51C21.5 8.76 21.41 9.01 21.31 9.27L15.98 9.27L13.43 4.42L11.04 11.87L8.77 4.03L6.91 9.27L1.02 9.27C0.92 9.01 0.83 8.76 0.77 8.51ZM15.3 10.42L13.68 7.33L10.98 15.75L8.66 7.73L7.71 10.42L1.58 10.42C4.11 14.7 11.16 19.21 11.16 19.21C11.16 19.21 18.22 14.7 20.75 10.41L15.3 10.41L15.3 10.42Z" />
  </StyledSvg>
);

AverageHeartRate.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string
};

export default AverageHeartRate;
