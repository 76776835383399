import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const ConnectFitnessDeviceSVG = ({ marginTop, viewBox }) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 25 25'}  width="25" height="25">
    <path d="M12.8557 50.2146C13.7792 50.2146 14.5279 49.4382 14.5279 48.4806C14.5279 47.5229 13.7792 46.7466 12.8557 46.7466C11.9322 
      46.7466 11.1836 47.5229 11.1836 48.4806C11.1836 49.4382 11.9322 50.2146 12.8557 50.2146Z" fill="#4798D9"/>
    <path d="M23.5278 34.9454C24.059 34.9862 24.6 35.0168 25.1409 35.0168C25.318 35.0168 25.5049 35.0168 25.6819 34.9964V37.373V41.8304V46.2368C25.6819 
      49.1234 23.4 51.4898 20.6164 51.4898H5.10488C2.32127 51.4898 0.0393066 49.1234 0.0393066 46.2368V5.753C0.0393066 2.8664 2.32127 0.5 5.10488 
      0.5H20.6164C23.4 0.5 25.6819 2.8664 25.6819 5.753V7.7624C25.5049 7.7522 25.318 7.7522 25.1409 7.7522C24.6 7.7522 24.059 7.7726 23.5278 
      7.8236V5.5082H2.11472V45.6146H23.5278V41.8406V34.9454ZM15.1672 2.3462H10.5442V3.0908H15.1672V2.3462ZM8.64586 1.7036C9.02947 1.7036 9.34422 2.03 
      9.34422 2.4278C9.34422 2.8256 9.02947 3.152 8.64586 3.152C8.26226 3.152 7.9475 2.8256 7.9475 2.4278C7.95734 2.0198 8.26226 1.7036 8.64586 1.7036ZM10.5442 
      48.4808C10.5442 49.8068 11.577 50.8778 12.8557 50.8778C14.1344 50.8778 15.1672 49.7966 15.1672 48.4808C15.1672 47.1548 14.1344 46.0838 12.8557 46.0838C11.577 
      46.0838 10.5442 47.1548 10.5442 48.4808Z" fill="#4798D9"/>
    <path d="M24.5312 9.30273C18.2164 9.30273 13.1017 14.6067 13.1017 21.1551C13.1017 27.7035 18.2164 33.0075 24.5312 33.0075C30.8459 33.0075 35.9607 27.7035 35.9607 
      21.1551C35.9607 14.6067 30.8459 9.30273 24.5312 9.30273Z" fill="#4798D9"/>
    <path d="M24.344 25.4887L24.3468 25.5H24.6503L24.6532 25.4887L26.2359 23.5643C25.6772 23.4145 25.0957 23.3354 24.5 23.3354C23.9043 23.3354 23.3228 23.4145 22.7641 
      23.5643L24.3468 25.4887H24.344ZM26.8146 22.8635L27.8613 21.589C26.8089 21.1369 25.6772 20.891 24.4972 20.891C23.3172 20.891 22.1854 21.1369 21.1331 21.589L22.1797 
      22.8635C22.9172 22.6205 23.6944 22.4876 24.4943 22.4876C25.2942 22.4876 26.0714 22.6176 26.8089 22.8635H26.8146ZM28.542 20.7639L30 18.9921C28.3321 18.0341 26.4685 17.5 
      24.5 17.5C22.5343 17.5 20.6707 18.0369 19 18.9921L20.458 20.7639C21.7089 20.162 23.0732 19.8313 24.5 19.8313C25.9268 19.8313 27.2911 20.162 28.542 20.7639Z" fill="white"/>
  </StyledSvg>
);

ConnectFitnessDeviceSVG.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
};

export default ConnectFitnessDeviceSVG;
