import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const Steps = ({color, marginTop, viewBox}) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 25 25'}  width="25" height="25">
    <path fillRule="evenodd" fill={color || "rgb(153, 153, 153)"}
      d="M18.644,12.150 C18.581,12.451 18.374,12.733 17.995,12.643 C16.628,12.319 14.486,12.223 13.218,12.529 C12.752,12.643 12.535,12.440 12.360,11.882 C11.924,10.501 11.235,8.836 11.383,6.702 C11.714,1.922 13.692,0.598 15.182,0.684 C16.672,0.770 20.502,3.135 18.644,12.150 ZM5.967,12.530 C4.699,12.223 2.557,12.319 1.191,12.643 C0.811,12.733 0.604,12.451 0.542,12.150 C-1.316,3.135 2.514,0.771 4.003,0.685 C5.493,0.600 7.471,1.923 7.803,6.703 C7.951,8.837 7.262,10.503 6.826,11.884 C6.650,12.438 6.434,12.642 5.967,12.530 ZM7.088,17.183 C7.337,19.418 6.855,20.967 4.669,21.282 C2.824,21.547 1.640,20.163 1.392,17.929 C1.244,16.579 1.230,15.717 1.230,15.717 L6.671,15.003 C6.671,15.003 6.938,15.831 7.088,17.183 ZM12.513,15.003 L17.954,15.717 C17.954,15.717 17.941,16.579 17.792,17.929 C17.546,20.163 16.362,21.547 14.516,21.282 C12.330,20.967 11.847,19.418 12.097,17.183 C12.246,15.831 12.513,15.003 12.513,15.003 Z" />
  </StyledSvg>
);

Steps.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
};

export default Steps;
