import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  max-width: 100px;
`;

const RightArrow = ({color, marginTop, viewBox, width, height}) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" width={width} height={height} marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 25 25'}>
    <path fillRule="evenodd" fill={color || "#FFFFFF"}
      d="M14.5728 11.6108L20 6.22739L14.5728 0.50016L13.1874 1.90326L16.2496 5.2351L0 5.2351V7.21968L16.2496 7.21968L13.1874 10.2077L14.5728 11.6108Z"/>
  </StyledSvg>
);

RightArrow.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

export default RightArrow;
