import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const Calories = ({color, marginTop, viewBox}) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 25 25'}  width="25" height="25">
    <path 
      fillRule="evenodd"
      fill={color || "rgb(153, 153, 153)"}
      d="M22.9982 1.74405C22.7736 1.61201 22.4969 1.61201 22.2724 1.74405L16.9961 5.41049C16.8972 5.4786 16.8375 5.59193 16.8365 
        5.71356V21.3329C16.8349 21.4703 16.9084 21.5974 17.0273 21.6625C17.1461 21.7277 17.2907 21.7203 17.4025 21.6433L23.0199 
        17.8069C23.2376 17.6719 23.3698 17.4307 23.3683 17.1712V2.38716C23.3678 2.12048 23.2264 1.87472 22.9982 1.74405Z" />
    <path 
      fillRule="evenodd"
      fill={color || "rgb(153, 153, 153)"}
      d="M8.12744 2.55711V18.2429C8.12716 18.3843 8.20605 18.5134 8.33065 18.5756L14.8625 21.7689C14.9751 21.8251 15.1082 21.8181 
        15.2145 21.7504C15.3208 21.6828 15.3853 21.564 15.385 21.4363V5.75045C15.3853 5.60912 15.3064 5.48 15.1818 5.41781L8.64999 
        2.22447C8.5374 2.16828 8.40431 2.17527 8.298 2.24295C8.19168 2.31063 8.12719 2.42941 8.12744 2.55711Z" />
    <path 
      fillRule="evenodd"
      fill={color || "rgb(153, 153, 153)"}
      d="M6.67588 2.66058C6.6648 2.52973 6.58637 2.41471 6.46987 2.35842C6.35337 2.30213 6.21629 2.31304 6.10979 2.38707L0.499665 
        6.18656C0.279252 6.31967 0.144088 6.56129 0.144043 6.82227V21.6063C0.144088 21.8719 0.28403 22.117 0.510625 22.2485C0.737221 
        22.3799 1.01563 22.3774 1.23994 22.242L6.51622 18.5903C6.61729 18.5208 6.67728 18.4041 6.67588 18.2799V2.66058Z" />
  </StyledSvg>
);

Calories.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
};

export default Calories;
