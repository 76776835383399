import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const Adventure = ({color, marginTop, viewBox}) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" width="8%" height="0.306in" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 25 25'}>
    <path fillRule="evenodd" fill={color || "rgb(153, 153, 153)"}
      d="M4.67,0.01c0,0 0,0 0,0c-0.23,0 -0.44,0.09 -0.6,0.24c-0.16,0.16 -0.25,0.37 -0.25,0.6v12.64c0,0.46 0.39,0.84 0.86,0.84c2,0
      5.35,0.41 7.66,2.79v-13.23c0,-0.16 -0.04,-0.3 -0.12,-0.43c-1.9,-3 -5.54,-3.45 -7.54,-3.46zM22.19,13.49v-12.64c0,-0.23
      -0.09,-0.44 -0.25,-0.6c-0.16,-0.16 -0.37,-0.24 -0.6,-0.24c0,0 0,0 0,0c-2,0 -5.65,0.46 -7.54,
      3.46c-0.08,0.12 -0.12,0.27 -0.12,0.43v13.23c2.31,-2.37 5.66,-2.78 7.66,-2.79c0.47,0 0.86,-0.38
      0.86,-0.84zM24.14,2.92h-0.62v10.57c0,1.18 -0.98,2.15 -2.19,2.15c-1.7,0 -4.49,0.33 -6.47,2.17c3.42,-0.82 7.03,-0.29 9.09,
      0.17c0.26,0.06 0.52,0 0.73,-0.16c0.21,-0.16 0.32,-0.4 0.32,-0.66v-13.39c0,-0.46 -0.39,-0.84 -0.86,-0.84zM2.48,13.49v-10.57h-0.62c-0.47,
      0 -0.86,0.38 -0.86,0.84v13.39c0,0.26 0.12,0.5 0.32,0.66c0.21,0.16 0.47,0.22 0.73,0.16c2.06,-0.46 5.67,-1 9.09,-0.17c-1.98,-1.84
      -4.78,-2.16 -6.47,-2.17c-1.21,0 -2.19,-0.97 -2.19,-2.15z" />
  </StyledSvg>
);

Adventure.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string
};

export default Adventure;
