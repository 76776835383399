import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

const CalendarIcon = ({color, marginTop, viewBox, width, height}) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" width={width} height={height} marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 25 25'}>
    <path fillRule="evenodd" fill={color || "#FFFFFF"}
      d="M5.25 1H4.1875V3.125H5.25V1ZM15.875 2.0625H18V18H1V2.0625H3.125V4.1875H6.3125V2.0625H12.6875V4.1875H15.875V2.0625ZM2.0625 16.9375H16.9375V5.25H2.0625V16.9375ZM13.75 1H14.8125V3.125H13.75V1Z" />
    <path fillRule="evenodd" fill={color || "#FFFFFF"}
      d="M4.1875 1V0.744H3.9315V1H4.1875ZM5.25 1H5.506V0.744H5.25V1ZM4.1875 3.125H3.9315V3.381H4.1875V3.125ZM5.25 3.125V3.381H5.506V3.125H5.25ZM18 2.0625H18.256V1.8065H18V2.0625ZM15.875 2.0625V1.8065H15.619V2.0625H15.875ZM18
    18V18.256H18.256V18H18ZM1 18H0.744V18.256H1V18ZM1 2.0625V1.8065H0.744V2.0625H1ZM3.125 2.0625H3.381V1.8065H3.125V2.0625ZM3.125 4.1875H2.869V4.4435H3.125V4.1875ZM6.3125 4.1875V4.4435H6.5685V4.1875H6.3125ZM6.3125 
    2.0625V1.8065H6.0565V2.0625H6.3125ZM12.6875 2.0625H12.9435V1.8065H12.6875V2.0625ZM12.6875 4.1875H12.4315V4.4435H12.6875V4.1875ZM15.875 4.1875V4.4435H16.131V4.1875H15.875ZM16.9375 16.9375V17.1935H17.1935V16.9375H16.9375ZM2.0625 
    16.9375H1.8065V17.1935H2.0625V16.9375ZM16.9375 5.25H17.1935V4.994H16.9375V5.25ZM2.0625 5.25V4.994H1.8065V5.25H2.0625ZM14.8125 1H15.0685V0.744H14.8125V1ZM13.75 1V0.744H13.494V1H13.75ZM14.8125 3.125V3.381H15.0685V3.125H14.8125ZM13.75 
    3.125H13.494V3.381H13.75V3.125ZM4.1875 1.256H5.25V0.744H4.1875V1.256ZM4.4435 3.125V1H3.9315V3.125H4.4435ZM5.25 2.869H4.1875V3.381H5.25V2.869ZM4.994 1V3.125H5.506V1H4.994ZM18 1.8065H15.875V2.3185H18V1.8065ZM18.256 18V2.0625H17.744V18H18.256ZM1 
    18.256H18V17.744H1V18.256ZM0.744 2.0625V18H1.256V2.0625H0.744ZM3.125 1.8065H1V2.3185H3.125V1.8065ZM3.381 4.1875V2.0625H2.869V4.1875H3.381ZM6.3125 3.9315H3.125V4.4435H6.3125V3.9315ZM6.0565 2.0625V4.1875H6.5685V2.0625H6.0565ZM12.6875 
    1.8065H6.3125V2.3185H12.6875V1.8065ZM12.9435 4.1875V2.0625H12.4315V4.1875H12.9435ZM15.875 3.9315H12.6875V4.4435H15.875V3.9315ZM15.619 2.0625V4.1875H16.131V2.0625H15.619ZM16.9375 16.6815H2.0625V17.1935H16.9375V16.6815ZM16.6815 
    5.25V16.9375H17.1935V5.25H16.6815ZM2.0625 5.506H16.9375V4.994H2.0625V5.506ZM2.3185 16.9375V5.25H1.8065V16.9375H2.3185ZM14.8125 0.744H13.75V1.256H14.8125V0.744ZM15.0685 3.125V1H14.5565V3.125H15.0685ZM13.75 3.381H14.8125V2.869H13.75V3.381ZM13.494 
    1V3.125H14.006V1H13.494Z" />
  </StyledSvg>
);

CalendarIcon.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

export default CalendarIcon;
