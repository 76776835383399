import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const Calories = ({color, marginTop, viewBox}) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 25 25'}  width="25" height="25">
    <path
      fillRule="evenodd"
      fill={color || "rgb(153, 153, 153)"}
      d="M5.685,21.649 C4.248,18.682 5.013,16.982 6.118,15.380 C7.327,13.626 7.639,11.890 7.639,11.890 C7.639,11.890 8.590,
        13.116 8.210,15.035 C9.890,13.179 10.207,10.223 9.953,9.092 C13.751,11.724 15.373,17.424 13.186,21.649 C24.819,15.119 16.080,
        5.350 14.558,4.249 C15.066,5.350 15.162,7.212 14.137,8.117 C12.403,1.593 8.115,0.255 8.115,0.255 C8.622,3.620 6.276,7.299 4.014,
        10.048 C3.935,8.706 3.850,7.781 3.139,6.497 C2.980,8.934 1.102,10.921 0.594,13.362 C-0.095,16.669 1.109,19.091 5.685,21.649 Z" />
  </StyledSvg>
);

Calories.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
};

export default Calories;
